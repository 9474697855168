import React from "react";
import { Helmet } from 'react-helmet';

let styles = {
    section: {
        color: '#ffffff',
        textAlign: 'center',
        fontWeight: '400'
    },
    description: {
        fontWeight: '400'
    },
    phoneNumber: {
        fontSize: '1.5em'
    },
};

function Pricing() {
    return (
        <section>
            <Helmet>
                <title>Professional Water Testing Services at Competitive Pricing | Chinook Water Testing</title>
                <meta name="description" content="Chinook Water Testing offers professional water testing services at competitive pricing in North Idaho and Eastern Washington. Contact Chinook Water Testing for cost-effective and reliable testing solutions." />
                <meta name="keywords" content="water testing, pricing, North Idaho, Eastern Washington, competitive pricing, reliable testing, Chinook Water" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://www.chinookwater.com/pricing" />
                <meta property="og:title" content="Professional Water Testing Services at Competitive Pricing | Chinook Water Testing" />
                <meta property="og:description" content="Chinook Water Testing offers professional water testing services at competitive pricing in North Idaho and Eastern Washington. Contact Chinook Water Testing for cost-effective and reliable testing solutions." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.chinookwater.com/pricing" />
                <meta property="og:image" content="https://www.chinookwater.com/og-image.jpg" />
                <meta property="og:site_name" content="Chinook Water" />
            </Helmet>
            <header className="sr-only">
                Professional Water Testing Services at Competitive Pricing
            </header>
            <main>
                <section style={styles.section}>
                    <h1 style={styles.description}>Call us today for fast service and competitive pricing!</h1>
                    <h2 style={styles.phoneNumber}>+1 (208) 818-9080</h2>
                </section>
            </main>
        </section>
    );
}

export default Pricing;
