import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ChinookLogo from './../../Assets/Images/chinookLogo.jpg';
import HomeIcon from '@mui/icons-material/Home';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PaidIcon from '@mui/icons-material/Paid';
import { HashLink as Link } from 'react-router-hash-link';
// import FacebookIcon from '@mui/icons-material/Facebook';

const drawerWidth = 240;

function NavBar(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <nav onClick={handleDrawerToggle} sx={{ textAlign: 'center', backgroundColor: '#ffffff' }}>
            <img
                src={ChinookLogo}
                alt='chinook water testing service company logo'
                style={{
                    width: '150px',
                    marginTop: 20,
                    marginBottom: 10,
                    marginLeft: 45,
                }}
            />
            <Divider />
            <List>
                <ListItem>
                    <Link to='/'>
                        <ListItemButton sx={{ textAlign: 'center', padding: '0 20px' }} >
                            <HomeIcon fontSize='medium' sx={{ marginRight: '10px', color: '#114796' }} />
                            <p style={{ fontSize: "1.2em", fontWeight: 500 }}>Home</p>
                        </ListItemButton>
                    </Link>
                </ListItem>
                <ListItem>
                    <Link to='/pricing'>
                        <ListItemButton sx={{ textAlign: 'center', padding: '0 20px' }}>
                            <PaidIcon sx={{ marginRight: '10px', color: '#114796' }} />
                            <p style={{ fontSize: "1.2em", fontWeight: 500 }}>Pricing</p>
                        </ListItemButton>
                    </Link>
                </ListItem>
                <ListItem>
                    <Link to='/contact'>
                        <ListItemButton sx={{ textAlign: 'center', padding: '0 20px' }}>
                            <ContactMailIcon sx={{ marginRight: '10px', color: '#114796' }} />
                            <p style={{ fontSize: "1.2em", fontWeight: 500 }}>Contact</p>
                        </ListItemButton>
                    </Link>
                </ListItem>
                {/* <ListItem>
                    <Link to='https://www.facebook.com/profile.php?id=100093605359037&mibextid=LQQJ4d'>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                            <FacebookIcon sx={{ marginRight: '10px', color: '#114796' }} />
                            <ListItemText style={{fontSize: "1.2em"}} primary='Facebook' />
                        </ListItemButton>
                    </Link>
                </ListItem> */}
            </List>
        </nav>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <nav sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar component="nav" style={{ backgroundColor: '#2c4a75' }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' } }} style={{ alignItems: 'center' }}  >
                        <Link to='/'>
                            <img
                                src={ChinookLogo}
                                width="100"
                                alt='chinook water testing service company logo'
                                style={{
                                    margin: '15px 20px 7px 0px',
                                    border: '10px solid white', borderRadius: '50%',
                                }} />
                        </Link>
                        <Typography variant='p'
                            style={{
                                fontFamily: 'sans-serif',
                                fontSize: '1.2em'
                            }}>
                            +1 (208) 818-9080
                        </Typography>
                    </Box>
                    <Box sx={{
                        flexGrow: 1,
                        display: { xs: 'flex', sm: 'none' },
                        alignItems: 'center',
                        justifyContent: 'right'
                    }}>
                        <Typography variant='p'
                            style={{
                                marginRight: 15,
                                fontFamily: 'sans-serif',
                                fontSize: '1em'
                            }}>
                            +1 (208) 818-9080
                        </Typography>
                        <Link to='/'>
                            <img
                                src={ChinookLogo}
                                width="70"
                                alt='chinook water testing service company logo' style={{
                                    border: '3px solid white',
                                    borderRadius: '50%',
                                    marginTop: 8,
                                }} />
                        </Link>
                    </Box>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        <Link to='/'>
                            <Button sx={{ color: '#fff' }}>
                                Home
                            </Button>
                        </Link>
                        <Link to='/pricing'>
                            <Button sx={{ color: '#fff' }}>
                                Pricing
                            </Button>
                        </Link>
                        <Link to='/contact'>
                            <Button sx={{ color: '#fff' }}>
                                Contact
                            </Button>
                        </Link>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </nav>
    );
}

NavBar.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default NavBar;