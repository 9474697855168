import React from "react";
import { Helmet } from 'react-helmet';
import ContactForm from "../../Components/ContactForm/ContactForm";

function Contact() {
    return (
        <section>
            <Helmet>
                <title>Contact Chinook Water Testing for Water Testing Services | Chinook Water</title>
                <meta name="description" content="Contact Chinook Water Testing for reliable water testing services in North Idaho and Eastern Washington. Reach out for expert assistance and testing solutions." />
                <meta name="keywords" content="water testing, contact, North Idaho, Eastern Washington, reliable testing, Chinook Water" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://www.chinookwater.com/contact" />
                <meta property="og:title" content="Contact Chinook Water Testing for Water Testing Services | Chinook Water Testing" />
                <meta property="og:description" content="Contact Chinook Water Testing for reliable water testing services in North Idaho and Eastern Washington. Reach out for expert assistance and testing solutions." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.chinookwater.com/contact" />
                <meta property="og:image" content="https://www.chinookwater.com/og-image.jpg" />
                <meta property="og:site_name" content="Chinook Water" />
            </Helmet>
            <header className="sr-only">
                Contact Chinook Water Testing for Reliable Water Testing Services
            </header>
            <main>
                <ContactForm />
            </main>
        </section>
    );
}

export default Contact;
