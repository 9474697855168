import { Helmet } from 'react-helmet';
import Services from './../../Components/Services/Services';

function Home() {
    return (
        <section>
            <Helmet>
                <title>Water Testing Services in North Idaho & Eastern Washington | Chinook Water</title>
                <meta name="description" content="Leading water testing services in North Idaho and Eastern Washington. Contact Chinook Water for reliable testing today." />
                <meta name="keywords" content="water testing, North Idaho, Eastern Washington, reliable testing, Chinook Water" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://www.chinookwater.com" />
                <meta property="og:title" content="Water Testing Services in North Idaho & Eastern Washington | Chinook Water Testing" />
                <meta property="og:description" content="Leading water testing services in North Idaho and Eastern Washington. Contact Chinook Water for reliable testing today." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.chinookwater.com" />
                <meta property="og:image" content="https://www.chinookwater.com/og-image.jpg" />
                <meta property="og:site_name" content="Chinook Water" />
            </Helmet>
            <header className="sr-only">
                Leading Water Testing Services for North Idaho and Eastern Washington
            </header>
            <main>
                <Services />
            </main>
        </section>
    );
}

export default Home;
