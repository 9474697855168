import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 650,
        [theme.breakpoints.down('md')]: {
            maxWidth: 575,
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: 100,
            marginBottom: 10,
        },
    },
    form: {
        padding: 15,
        background: `linear-gradient(90deg, rgb(44 74 117 / 33%) 0%, rgb(25 118 210 / 37%) 27%, rgb(84 119 155 / 50%) 100%)`,
        [theme.breakpoints.down('md')]: {
            maxWidth: '550px',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '550px',
            margin: '60px auto 60px',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            maxWidth: '420px',
            margin: '10% auto',
        },
    },
    paper: {
        padding: '25px',
        backgroundColor: '#eaeaea',
    },
    phoneNumber: {
        color: '#f6961b',
        fontWeight: '600',
        fontSize: '1rem',
    },
    formMessage: {
        fontWeight: 700,
        fontSize: '1em',
        margin: '5% 5% 10%',
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            fontSize: '.8em',
        },
    }
}));

export default useStyles;