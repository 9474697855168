import { HashLink as Link } from 'react-router-hash-link';
import { Grid } from "@material-ui/core";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions, Box } from '@mui/material';
import InspectionImage from './../../Assets/Images/inspection.jpg';
import ProductivityImage from './../../Assets/Images/productivity.jpg';
import RiverImage from './../../Assets/Images/river.jpg';
import VaPdf from '../VaPdf/VaPdf';
import useStyles from './Services.Styles';

function Services() {
    const classes = useStyles();
    return (
        <main>
            <h1 className={classes.title}>
                Well Water Testing Services for VA, FHA & HUD Loans
            </h1>
            <h2 className={classes.subTitle}>
                Serving the Inland Northwest in Idaho & Eastern Washington
            </h2>
            <Box className={classes.pdfBox}>
                <VaPdf />
            </Box>
            <Grid container spacing={4} justifyContent="center" alignItems="center">
                <Grid item xs={12} md={4}>
                    <Card style={{ borderRadius: 10, padding: 10 }}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="250"
                                image={RiverImage}
                                alt="accurate well water testing"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h2" component="div" style={{ fontWeight: 'bold', fontSize: '1.7em' }}>
                                    Sampling & Testing
                                </Typography>
                                <Typography variant='h3' style={{ color: '#a55206', fontSize: '1.2em' }}>
                                    Well Water Testing to ensure compliance with Local Health Codes
                                </Typography>
                                <ul style={{ listStyleType: 'disclosure-closed', fontSize: '1.2em' }}>
                                    <li style={{ marginBottom: 5 }}>Bacterial Testing (State Certified Lab)</li>
                                    <li style={{ marginBottom: 5 }}>Potability Testing</li>
                                    <li style={{ marginBottom: 5 }}>Heavy Metals (upon request)</li>
                                </ul>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-evenly',
                                alignItems: 'center',
                                backgroundColor: '#1976d2',
                                width: '100%',
                                height: 40,
                            }}>
                                <Link to="/pricing">
                                    <Button size="small" style={{ color: '#fff', fontWeight: '600' }}>Pricing</Button>
                                </Link>
                                <Link to="/contact">
                                    <Button size="small" style={{ color: '#fff', fontWeight: '600' }}>Contact Us</Button>
                                </Link>
                            </Box>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card style={{ borderRadius: 10, padding: 10 }}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="250"
                                image={InspectionImage}
                                alt="well water testing and inspection"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h2" component="div" style={{ fontWeight: 'bold', fontSize: '1.7em' }}>
                                    Inspection
                                </Typography>
                                <Typography variant='h3' style={{ color: '#a55206', fontSize: '1.2em' }}>
                                    Water and Wastewater System Inspection
                                </Typography>
                                <ul style={{ listStyleType: 'disclosure-closed', fontSize: '1.2em' }}>
                                    <li style={{ marginBottom: 5 }}>Operation</li>
                                    <li style={{ marginBottom: 5 }}>Consulting</li>
                                    <li style={{ marginBottom: 5 }}>State Certified</li>
                                </ul>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-evenly',
                                alignItems: 'center',
                                backgroundColor: '#1976d2',
                                width: '100%',
                                height: 40,
                            }}>
                                <Link to="/pricing">
                                    <Button size="small" style={{ color: '#fff', fontWeight: '600' }}>Pricing</Button>
                                </Link>
                                <Link to="/contact">
                                    <Button size="small" style={{ color: '#fff', fontWeight: '600' }}>Contact Us</Button>
                                </Link>
                            </Box>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card style={{ borderRadius: 10, padding: 10 }}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="250"
                                image={ProductivityImage}
                                alt="flow monitoring for water testing and inspection services"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h2" component="div" style={{ fontWeight: 'bold', fontSize: '1.7em' }}>
                                    Productivity
                                </Typography>
                                <Typography variant='h3' style={{ color: '#a55206', fontSize: '1.2em' }}>
                                    Flow Monitoring
                                </Typography>
                                <ul style={{ listStyleType: 'disclosure-closed', fontSize: '1.2em' }}>
                                    <li style={{ marginBottom: 5 }}>HUD 4000.1 Productivity Verification</li>
                                    <li style={{ marginBottom: 5 }}>VA Home Loan Minimum Property Requirements</li>
                                    <li style={{ marginBottom: 5 }}>System Production</li>
                                </ul>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-evenly',
                                alignItems: 'center',
                                backgroundColor: '#1976d2',
                                width: '100%',
                                height: 40,
                            }}>
                                <Link to="/pricing">
                                    <Button size="small" style={{ color: '#fff', fontWeight: '600' }}>Pricing</Button>
                                </Link>
                                <Link to="/contact">
                                    <Button size="small" style={{ color: '#fff', fontWeight: '600' }}>Contact Us</Button>
                                </Link>
                            </Box>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </main>
    );
}

export default Services;