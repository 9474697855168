import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    titleBox: {
        marginBottom: 100,
    },
    title: {
        marginTop: 100,
        marginBottom: 20,
        textAlign: 'center',
        fontSize: '2.5em',
        color: '#fff',
        fontFamily: 'sans-serif',
        fontWeight: 500,
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.8em'
        },
    },
    subTitle: {
        textAlign: 'center',
        color: '#fff',
        fontFamily: 'sans-serif',
        marginTop: 10,
        marginBottom: 20,
        fontWeight: 400,
        fontSize: '1.3em',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1em',
        },
    },
    pdfBox: {
        marginTop: 10,
        marginBottom: 50,
    }
}));

export default useStyles;