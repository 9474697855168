import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '50px',
        position: 'absolute',
        bottom: 0,
        width: '100%', 
        backgroundColor: '#6979a073',
        color: 'white',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        clear: 'both',
        [theme.breakpoints.down('sm')]: {
            position: 'inherit',
            marginTop: 50,
        },
    },
    footerText: {
        fontFamily: 'monospace',
        [theme.breakpoints.down('xs')]: {
            fontSize: '.8em'
        },
    },
    flag: {
        width: 40,
        margin: '0 10px'
    }
}));

export default useStyles;