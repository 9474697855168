import { Routes, Route, useLocation } from "react-router-dom";
import NavBar from './Components/Navigation/NavBar';
import Footer from './Components/Footer/Footer';
import Home from "./Views/Home/Home";
import Contact from "./Views/Contact/Contact";
import Pricing from "./Views/Pricing/Pricing";
import Error from './Components/Error/Error';

function App() {
    const location = useLocation();

    return (
        <main>
            <div className="root">
                <NavBar />
                <Routes>
                    <Route
                        key={location.key}
                        exact path="/"
                        element={<Home />}
                        errorElement={<Error />} />
                    <Route
                        path="/pricing"
                        element={<Pricing />}
                        errorElement={<Error />} />
                    <Route
                        path="/contact"
                        element={<Contact />}
                        errorElement={<Error />} />
                </Routes>
            </div>
            <Footer />
        </main>
    );
}

export default App;
